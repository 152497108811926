<template>
  <v-container>
     <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
    <PageHeader :title="title" class="pt-5 pb-2" />
    <v-card>
      <v-data-table
        id="servidoresTable"
        :headers="servidoresHeader"
        :header-props="{ sortIcon: null }"
        class="elevation-1"
        :items="servidoresEmail"
        :search="search"
        item-key="esId"
        :sort-desc.sync="sortDesc"
        :footer-props="{
          'items-per-page-options': itemsPerPageOptions
        }"
        :items-per-page="itemPerPage"
      >
        <template v-slot:[`item.usaSsl`]="{ item }">
        <v-icon v-if="item.usaSsl" small> {{ check }} </v-icon>
        </template>
        <template v-slot:top v-if="canCreate">
          <v-toolbar flat>
            <v-row>
              <v-col cols="10">
                <v-text-field
                  v-model="search"
                  :append-icon= "searchIcon"
                  label="Buscar"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="2" align="end">
                <v-btn color="primary" @click="newServidor" class="to-right">
                  Nuevo
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="pl-2 pr-2"
                @click="openSendTestEmail(item)"
                v-bind="attrs"
                size="20"
                v-on="on"
                >{{ emailSendIcon }}
              </v-icon>
            </template>
            <span>Enviar email de prueba</span>
          </v-tooltip>
          <v-tooltip left v-if="canEdit">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="editServidor(item)"
              >
                {{ editIcon }}
              </v-icon>
            </template>
            <span>Editar servidor</span>
          </v-tooltip>
          <v-tooltip left v-if="canDelete">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="openDeleteServidor(item)"
              >
                {{ deleteIcon }}
              </v-icon>
            </template>
            <span>Eliminar servidor</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <div v-if="modalSendTestEmail">
        <v-dialog
        v-model="modalSendTestEmail"
        :max-width="'30%'"
        @keydown.esc="modalSendTestEmail = false"
        >
          <v-card>
            <v-card-title>
              <span class="pl-1 primary--text">{{ sendTestEmailTitle }}</span>
            </v-card-title>
            <v-card-text class="pb-0" >
              <v-container>
                <v-form v-model="isFormValid">
                  <v-row>
                    <v-col cols="12" sm="12" class="pb-0">
                      <v-text-field
                        v-model="emailAddresToSend"
                        outlined
                        dense
                        label="Email Destinatario"
                        :rules="rules.required.concat(rules.email)"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn outlined @click="closeModalAndRefresh"> Cancelar </v-btn>
              <v-btn
                color="primary"
                :loading="sendingEmail"
                :disabled="!isFormValid"
                elevation="2"
                @click="sendTestEmailServidor()"
              >
                Enviar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
    <div v-if="openModalEdit">
      <v-dialog
      v-model="openModalEdit"
      :max-width="maxWidth ? '60%' : '40%'"
      @keydown.esc="openModalEdit = false"
      >
        <NuevoServidorEmail
          :esId="esId" 
          @close="closeModalEvent"
          @closeModal="closeModalAndRefresh"
          @onServidorSelected="maxWidth = true"
        />
      </v-dialog>
    </div>
    <DeleteDialog
      :openDelete.sync="openDeleteDialog"
      @onDeleteItem="deleteServidor()"
    />
     </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0 "
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import { mapActions } from "vuex";
import DeleteDialog from "@/components/shared/DeleteDialog";
import NuevoServidorEmail from "@/components/modules/email/configuracion/NuevoServidorEmail";
import Ayuda from "@/components/shared/Ayuda.vue";


export default {
  name: "ServidoresEmail",
  components: {
    PageHeader,
    DeleteDialog,
    NuevoServidorEmail,
    Ayuda
  },
  data: () => ({
    title: enums.titles.SERVIDOR_EMAIL,
    check: enums.icons.CHECK_OUTLINE,
    searchIcon: enums.icons.SEARCH,
    emailSendIcon: enums.icons.EMAIL_SEND_OUTLINE,
    sendTestEmailTitle: enums.titles.SEND_TEST_EMAIL_SERVIDOR,
    defaultErrorMsg: enums.messages.SYSTEM_ERROR,
    sendingEmail: false,
    itemsPerPageOptions: [10, 20, 30, 40, 50],
    itemPerPage: 10,
    openDeleteDialog: false,
    servidoresEmail: [],
    search: "",
    maxWidth: false,
    servidorToDelete: {},
    esId: null,
    openModalEdit: false,
    sortDesc: true,
    editIcon: enums.icons.EDIT,
    deleteIcon: enums.icons.DELETE,
     optionCode: enums.webSiteOptions.SERVIDORES_EMAIL,
    showHelp: false,
    showIcon: true,
    showExpand: false,
    servidoresHeader: [
      {
        text: "Nombre",
        align: "start",
        value: "name"
      },
      { text: "Port", value: "smtpPort" },
      { text: "Usa SSL", value: "usaSsl" },
      { text: "Servicio", value: "serviceNombre" },
      { text: "Acciones", value: "actions", sortable: false, align: "end" }
    ],
    modalSendTestEmail: false,
    emailAddresToSend: null,
    rules: rules,
    isFormValid: false,
    allowedActions: null,
    canEdit: false,
    canDelete: false,
    canCreate: false,
  }),
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  created(){
    this.loadServidores();
  },
  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      fetchServidores: "email/fetchServidores",
      sendTestEmail:"email/sendTestEmail",
      deleteServidorEmail:"email/deleteServidorEmail"
    }),
    setPermisos() {
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.ELIMINAR_SERVIDORES_EMAIL:
            this.canDelete = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_SERVIDORES_EMAIL:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.NUEVO_SERVIDOR_EMAIL:
            this.canCreate = true;
            break;
          default:
            break;
        }
      });
    },
    async loadServidores(){
      const response = await this.fetchServidores();
      this.servidoresEmail = response;
    },
    closeModalEvent(){
      this.openModalEdit = false;
      this.maxWidth = false;
    },
    editServidor(item) {
      this.esId = item.esId;
      this.maxWidth = true;
      this.openModalEdit = true;
    },
    openDeleteServidor(item) {
      this.servidorToDelete = item;
      this.openDeleteDialog = true;
    },
    async deleteServidor() {
      const res = await this.deleteServidorEmail(this.servidorToDelete.esId);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Eliminado con éxito." });
        this.loadServidores();
      }
      this.servidorToDelete = {};
    },
    newServidor() {
      this.openModalEdit = true;
    },
    closeModalAndRefresh(){
      this.openModalEdit = false;
      this.maxWidth = false;
      this.modalSendTestEmail = false;
      this.emailAddresToSend = null;
      this.esId = null;
      this.loadServidores();
    },
    openSendTestEmail(item){
      this.esId = item.esId;
      this.modalSendTestEmail = true;
    },
    async sendTestEmailServidor(){
      this.isFormValid = false;
      this.sendingEmail = true;
      try {
        const response = await this.sendTestEmail({ esId:this.esId, emailAddres: this.emailAddresToSend});
        if(response.status ===200){
          this.setAlert({ type: "success", message: "Enviado con éxito" });
          this.sendingEmail = false;
          this.closeModalAndRefresh();
        }
      } catch (e) {
        this.sendingEmail = false;
        this.isFormValid = true;
        this.setAlert({ type: "error", message: this.defaultErrorMsg });
        this.closeModalAndRefresh();
      }
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>

<style lang="scss" scoped>
</style>