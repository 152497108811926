<template>
  <v-card>
    <v-card-title>
      <span class="pl-1 primary--text">{{
        esId ? `${titleEdit}` : titleNew
      }}</span>
    </v-card-title>
    <v-item-group v-model="servidorSelected">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" sm="3" align="center">
            <v-hover v-slot="{ hover }">
              <v-item v-slot:default="{ active, toggle }" value="1">
                <v-sheet
                  :color="active || smtpActive ? 'rgba(25,84,114,0.5)' : ''"
                  :elevation="hover ? 12 : 2"
                  class="d-flex align-center mySheet"
                  :class="{ 'on-hover': hover }"
                  outlined
                  rounded
                  height="100"
                  width="100"
                  @click="toggle"
                >
                  <v-container>
                    <v-img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAXVBMVEVHcEwykZkykZkykZkykZkykZkykZkykZkykZkykZn///9lrbPk8PGZyMyt09aEvcJprrSSxMnx+PhAmKDM5OZ2trtbp61NoKfI4uS72t3W6eufzM9ZpqxMn6Z/ur9e2KIXAAAACnRSTlMAsBD/IKAwQMDwVDRpfQAAAAlwSFlzAAAE0AAABNABiiVDWwAAAltJREFUaIHt2ut2oyAQAOB0J203U4qA9RZ39/0fc89w89IgplJPemR+GSt8OsDQ5HiaxfkJNsfTed7rNH5dthsAl2XlOYUB8LyIJHkQgMsiksYAyMjhkYxkZAXy521V/N2EfOCqeM9IRu5H7oqMHB55QOTwVTgjPx25KzJyeOQBkX/vq+JtE3L4ApmRb0Tuioz8GOTlN0UqRHf2MjfOiX7iHMenX25f0xsArxlJgQipqAK1sqJPjI67cTuhC1QhP9WshrkjZdoGkbIbNastguUI4XEEEa/1AnIdXcjck2AxQtQqRN9hAKGmirpsZIfSI+1gVDhh6bmEPaZrdTaku8XbiHK3QNeWpmE7yVeH2EUQgJr6qQJISdmcTA+GSPcl3edSmRPLCPSI2AeQZpoai3SjkwUiE3GksnMy9CSqnCPUorGfW0S5AhH2bm+OSTt/FEJ0imy2aMxWIAUi8hCi5w4rJkhPg21HStJRFUe4Hcfb66S3S7YcGnIY8nWltkUU0VWhCZcVt8h4OSA+X41uG0OELgqUrWDtalxhqTxCXV3tHbSwiAx1BZYQt2JNRwap7Bpl+uQahEMEcUmjyWwQMPmqzdqPItwP6fJ+oqud9IjJV29WWHzgfUQ2LTvTLSJ0vpQZqHRIM0Go/17YcpAOqUyJc0iPyLg93ojUwv+5M5PYIWI0rTciBSqpV51oh53RzEa9ISpIgsw3UI/oetOnRsy/Ah6p/b69eeBryW2FrHxD7o8YJEFSR0YeH9nlm9Yu3xltpEIC3WfkC8guLyzt8urVLi+RfcPrcKfT6T/McZloAkSkigAAAABJRU5ErkJggg=="
                    ></v-img>
                  </v-container>
                  <v-scroll-y-transition>
                    <div
                      class="title flex-grow-1 text-center"
                      v-if="active"
                    ></div>
                  </v-scroll-y-transition>
                </v-sheet>
              </v-item>
            </v-hover>
          </v-col>
          <v-col cols="12" sm="3" align="center">
            <v-hover v-slot="{ hover }">
              <v-item v-slot:default="{ active, toggle }" value="2">
                <v-sheet
                  :color="active || mailKitActive ? 'rgba(25,84,114,0.5)' : ''"
                  :elevation="hover ? 12 : 2"
                  class="d-flex align-center mySheet"
                  :class="{ 'on-hover': hover }"
                  outlined
                  rounded
                  height="100"
                  width="100"
                  @click="toggle"
                >
                  <v-container>
                    <v-img
                      height="97px"
                      width="97px"
                      src="@/assets/img/logo_mailKit.png"
                    ></v-img>
                  </v-container>
                  <v-scroll-y-transition>
                    <div
                      class="title flex-grow-1 text-center"
                      v-if="active"
                    ></div>
                  </v-scroll-y-transition>
                </v-sheet>
              </v-item>
            </v-hover>
          </v-col>
          <v-col cols="12" sm="3" align="center">
            <v-hover v-slot="{ hover }">
              <v-item v-slot:default="{ active, toggle }" value="3">
                <v-sheet
                  :color="active || sendGridActive ? 'rgba(25,84,114,0.5)' : ''"
                  :elevation="hover ? 12 : 2"
                  class="d-flex align-center mySheet"
                  :class="[{ 'on-hover': hover }]"
                  outlined
                  rounded
                  height="100"
                  width="100"
                  @click="toggle"
                >
                  <v-img src="@/assets/img/logo_sendGrid.png"></v-img>
                  <v-scroll-y-transition>
                    <div
                      class="title flex-grow-1 text-center"
                      v-if="active"
                    ></div>
                  </v-scroll-y-transition>
                </v-sheet>
              </v-item>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </v-item-group>
    <div v-if="notInicial">
      <v-card>
        <v-container>
          <v-card-text class="pb-0">
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model.trim="nombre"
                  label="Nombre"
                  outlined
                  dense
                  :rules="rules.required.concat([rules.requiredTrim(nombre)])"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <div v-show="servidorSelected == servidores.smtp">
            <v-container class="pt-0 pb-0">
              <v-form v-model="isSmtpFormValid">
                <v-row class="pt-0">
                  <v-col cols="12" md="6" class="pb-0">
                    <v-text-field
                      v-model.trim="smtpHost"
                      label="Host"
                      outlined
                      dense
                      :rules="
                        rules.required.concat([rules.requiredTrim(smtpHost)])
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" class="pb-0">
                    <v-text-field
                      v-model="smtpPort"
                      label="SMTP Port"
                      outlined
                      dense
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" class="pt-0">
                    <v-checkbox v-model="usaSsl" label="Usa SSL"> </v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="pb-0">
                    <v-text-field
                      v-model.trim="addres"
                      label="Usuario"
                      outlined
                      dense
                      :rules="
                        rules.required.concat([rules.requiredTrim(addres)])
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="pb-0">
                    <v-text-field
                      v-model="password"
                      label="Contraseña"
                      outlined
                      dense
                      :append-icon="
                        !password ? '' : textType ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      @click:append="() => (textType = !textType)"
                      :type="textType ? 'password' : 'text'"
                      @focus="inputActive = 'password'"
                      @blur="inputActive = ''"
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </div>
          <div v-show="servidorSelected == servidores.mailKit">
            <v-container class="pt-0 pb-0">
              <v-form v-model="isMailKitFormValid">
                <v-row class="pt-0">
                  <v-col cols="12" md="6" class="pb-0">
                    <v-text-field
                      v-model.trim="smtpHost"
                      label="Host"
                      outlined
                      dense
                      :rules="
                        rules.required.concat([rules.requiredTrim(smtpHost)])
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" class="pb-0">
                    <v-text-field
                      v-model="smtpPort"
                      label="SMTP Port"
                      outlined
                      dense
                      onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="3" class="pt-0">
                    <v-checkbox v-model="usaSsl" label="Usa SSL"> </v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="pb-0">
                    <v-text-field
                      v-model.trim="addres"
                      label="Usuario"
                      outlined
                      dense
                      :rules="
                        rules.required.concat([rules.requiredTrim(addres)])
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="pb-0">
                    <v-text-field
                      v-model="password"
                      label="Contraseña"
                      outlined
                      dense
                      :append-icon="
                        !password ? '' : textType ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      @click:append="() => (textType = !textType)"
                      :type="textType ? 'password' : 'text'"
                      @focus="inputActive = 'password'"
                      @blur="inputActive = ''"
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6" class="pb-0">
                    <v-select
                      v-model="secureSocketOptionSelected"
                      label="Secure socket option"
                      :items="secureSocketOptions"
                      item-value="id"
                      item-text="value"
                      outlined
                      dense
                      clearable
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </div>
          <div v-show="servidorSelected == servidores.sendGrid">
            <v-container class="pt-0">
              <v-form v-model="isSendGridFormValid">
                <v-row class="pt-0">
                  <v-col cols="12" sm="12" md="12" class="pb-0">
                    <v-text-field
                      v-model.trim="apiKey"
                      label="API key"
                      outlined
                      dense
                      :rules="
                        rules.required.concat([rules.requiredTrim(apiKey)])
                      "
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </div>
          <v-container class="pt-0">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-checkbox
                  class="pr-3"
                  v-model="testEmail"
                  label="Enviar email de prueba"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-text-field
                  v-if="testEmail"
                  v-model="emailAddresToSend"
                  outlined
                  dense
                  label="Email Destinatario"
                  :rules="rules.required.concat(rules.email)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions class="pt-5">
            <v-spacer></v-spacer>
            <v-btn outlined @click="cancelar"> Cancelar </v-btn>
            <v-btn
              color="primary"
              :loading="sendingEmail"
              :disabled="!validateForm"
              elevation="2"
              @click="saveServidor()"
            >
              Guardar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";

export default {
  name: "NuevoServidorEmail",
  props: {
    modalOpen: {
      type: Boolean,
      require: true
    },
    esId: {
      type: Number,
      require: false,
      default: null
    }
  },
  components: {
    PageHeader
  },
  data() {
    return {
      textType: String,
      inputActive: "",
      title: "",
      titleNew: enums.titles.NUEVO_SERVIDOR_EMAIL,
      titleEdit: enums.titles.EDITAR_SERVIDOR_EMAIL,
      defaultErrorMsg: enums.messages.SYSTEM_ERROR,
      sendingEmail: false,
      rules: rules,
      isSmtpFormValid: false,
      isMailKitFormValid: false,
      isSendGridFormValid: false,
      servidorSelected: null,
      servidores: {
        smtp: 1,
        mailKit: 2,
        sendGrid: 3
      },
      notInicial: false,
      nombre: null,
      smtpHost: null,
      smtpPort: null,
      usaSsl: false,
      addres: null,
      password: null,
      apiKey: null,
      testEmail: false,
      emailAddresToSend: null,
      secureSocketOptionSelected: null,
      secureSocketOptions: [],
      smtpActive: false,
      mailKitActive: false,
      sendGridActive: false
    };
  },
  computed: {
    validateForm() {
      if (this.servidorSelected == 1 && this.isSmtpFormValid) return true;
      else if (this.servidorSelected == 2 && this.isMailKitFormValid)
        return true;
      else if (this.servidorSelected == 3 && this.isSendGridFormValid)
        return true;
      return false;
    }
  },
  watch: {
    servidorSelected(val) {
      if (val) {
        this.notInicial = true;
        this.$emit("onServidorSelected");
        if (val == 1) {
          this.smtpActive = true;
          this.mailKitActive = false;
          this.sendGridActive = false;
        } else if (val == 2) {
          this.smtpActive = false;
          this.mailKitActive = true;
          this.sendGridActive = false;
        } else {
          this.smtpActive = false;
          this.mailKitActive = false;
          this.sendGridActive = true;
        }
      }
    }
  },
  mounted() {
    this.getSecureSocketOptionEnum();
    if (this.esId) {
      this.setFormato();
    }
  },
  methods: {
    ...mapActions({
      saveServidorEmail: "email/saveServidorEmail",
      getServidorById: "email/getServidorById",
      sendTestEmail: "email/sendTestEmail",
      fetchSecureSocketOptionEnum: "email/fetchSecureSocketOptionEnum",
      setAlert: "user/setAlert"
    }),
    async getSecureSocketOptionEnum() {
      const response = await this.fetchSecureSocketOptionEnum();
      this.secureSocketOptions = response;
    },
    async setFormato() {
      const servidor = await this.getServidorById(this.esId);
      if (servidor.service === null) {
        this.servidorSelected = 1;
      } else {
        this.servidorSelected = servidor.service;
      }
      this.nombre = servidor.name;
      this.notInicial = true;
      this.isFormValid = true;
      switch (this.servidorSelected) {
        case 1:
          this.password = servidor.credentialFromMailPass;
          this.addres = servidor.credentialFromMailAddres;
          this.smtpPort = servidor.smtpPort;
          this.smtpHost = servidor.smtpHost;
          this.usaSsl = servidor.usaSsl;
          this.smtpActive = true;
          break;
        case 2:
          this.password = servidor.credentialFromMailPass;
          this.addres = servidor.credentialFromMailAddres;
          this.smtpPort = servidor.smtpPort;
          this.smtpHost = servidor.smtpHost;
          this.usaSsl = servidor.usaSsl;
          this.secureSocketOptionSelected = servidor.secureSocketOption;
          this.mailKitActive = true;
          break;
        case 3:
          this.apiKey = servidor.apiKey;
          this.sendGrid = true;
          break;
      }
    },
    async saveServidor() {
      if (this.testEmail) {
        if (this.emailAddresToSend !== null) {
          this.sendingEmail = true;
        } else {
          this.setAlert({
            type: "warning",
            message: "Ingrese un destinatario"
          });
        }
      }
      const data = {
        esId: this.esId,
        name: this.nombre,
        service: this.servidorSelected,
        smtpPort: this.smtpPort,
        smtpHost: this.smtpHost,
        usaSsl: this.usaSsl,
        credentialFromMailAddres: this.addres,
        credentialFromMailPass: this.password,
        secureSocketOptions: this.secureSocketOptionSelected,
        apiKey: this.apiKey
      };
      try {
        const res = await this.saveServidorEmail(data);
        if (res.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito" });
          if (this.testEmail) {
            const response = await this.sendTestEmail({
              esId: res.data,
              emailAddres: this.emailAddresToSend
            });
            if (response.status === 200) {
              this.setAlert({ type: "success", message: "Enviado con éxito" });
              this.sendingEmail = false;
            }
          }
          this.cleanFields();
          this.$emit("closeModal");
        }
      } catch (e) {
        this.sendingEmail = false;
        this.setAlert({ type: "error", message: this.defaultErrorMsg });
        this.$emit("closeModal");
      }
    },
    cleanFields() {
      this.nombre = null;
      this.smtpHost = null;
      this.smtpPort = null;
      this.usaSsl = false;
      this.addres = null;
      this.password = null;
      this.apiKey = null;
      this.emailAddresToSend = null;
    },
    cancelar() {
      this.cleanFields();
      this.$emit("closeModal");
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .v-label .theme--light {
  margin-bottom: 0%;
}
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}
.mySheet {
  cursor: pointer;
}
</style>
